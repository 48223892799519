import React, { useState } from 'react';
import '../css/LogIn.css';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { app } from '../firebase';

const LogIn = ({ goToPage, onUserLogin, goToPage2 }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    const db = getFirestore(app)

    try {
      const userQuery = query(collection(db, 'users'), where('user', '==', username), where('password', '==', password));
      const querySnapshot = await getDocs(userQuery);
      const userData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
      onUserLogin(userData)

    } catch (err) {
      setError('Credenciales incorrectas');
    }
  };

  return (
    <div id='app' className='app-login' style={{ backgroundColor: 'white' }}>
      <div id="login-header">
        <img src={require('../img/log-in/atras.webp')} alt="" id='atras' onClick={() => goToPage()} />
      </div>

      <div id="big-logo">
        <img src={require('../img/log-in/mi-argentina-big-logo.webp')} alt="" />
      </div>

      <form id='login-form' onSubmit={handleLogin}>
        <input
          type="email"
          placeholder='Ingrese su E-Mail'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="email"
        />
        <input
          type="password"
          placeholder='Contraseña'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <button type='submit'>
          <p>Ingresar a mi cuenta</p>
        </button>
        {error && <p id='error' style={{ color: 'red' }}>{error}</p>}
        <p id='recover'>Recuperar mi contraseña</p>
      </form>

      <div id="log-in-divider-line"></div>

      <div id="create-account">
        <p id='account-question'>¿No tenés cuenta en Mi Argentina?</p>
        <button onClick={goToPage2}>
          <p>Crear Cuenta</p>
        </button>
      </div>
    </div>
  );
};

export default LogIn;
