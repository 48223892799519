import React from 'react'
import Footer from '../components/Footer'
import '../css/MisTramites.css'

const MisTramites = ({ footerClass, footerHome, footerNovedades, footerTelefono }) => {
  return (
    <div id="app" className='app-mis-tramites'>
      <div id="misTramitesHeader">
        <img src={require('../img/mis-tramites/atras.webp')} alt="" id="atras" onClick={footerHome} />
        <p>Mis trámites</p>
      </div>

      <div id="misTramitesHeader-continue">
        <h2>realizá tus trámites de manera virtual.</h2>
        <img src={require('../img/mis-tramites/tramites.webp')} alt="" />
      </div>

      <div id="infoContainer" className='info1'>
        <div className="info">
          <p className="details">Consultá mas trámites en <span>TramitAr</span> o sacá un turno en Mis turnos.</p>
          <button>
            <p>Ir a mis turnos</p>
          </button>
        </div>
      </div>

      <div id="infoContainer" className='info2'>
        <div className="info">
          <div className="main-title">
            <img src={require('../img/mis-tramites/luz-y-gas.webp')} alt="" />
            <div id="divider-line2"></div>
            <p className="title">Solicitud de subsidios a la luz y gas</p>
          </div>

          <div id="divider-line"></div>

          <p className="details" style={{ marginTop: '0' }}>Inscribite para mantener los subsidios a los servicios de luz y gas de tu hogar.</p>
          <button>
            <p>Ir a la solicitud</p>
          </button>
        </div>
      </div>

      <div id="infoContainer">
        <div className="info">
          <div className="main-title">
            <img src={require('../img/mis-tramites/acceso.webp')} alt="" />
            <div id="divider-line2"></div>
            <p className="title">Solicitá tu Símbolo Internacional de Acceso</p>
          </div>

          <div id="divider-line"></div>

          <p className="details" style={{ marginTop: '0' }}>Si tenés un CUD o sos representante de una persona con discapacidad* podés tramitar el beneficio de libre tránsito y estacionamiento (derecho regulado por las normativas de tránsito de cada localidad o municipio).</p>
          <p className="details">* Sólo podrá efectuarlo el representante que hubiera realizado el trámite del CUD junto a la persona con discapacidad.</p>
          <button>
            <p>Solicitá tu símbolo</p>
          </button>
        </div>
      </div>

      <div id="infoContainer"  style={{paddingBottom: '85px'}}>
        <div className="info">
          <div className="main-title">
            <img src={require('../img/mis-tramites/peajes.webp')} alt="" />
            <div id="divider-line2"></div>
            <p className="title">Solicitá la exención de pago de peajes para personas con discapacidad.</p>
          </div>

          <div id="divider-line"></div>

          <p className="details" style={{ marginTop: '0' }}>Vialidad Nacional otorga, a través de las empresas concesionarias, la exención del pago de peajes de rutas nacionales concesionadas para personas con discapacidad.</p>
          <button style={{marginBottom: '15px'}}>
            <p>Solicitá tu pase libre</p>
          </button>

          <div id="divider-line"></div>

          <div id="changeDni">
            <img src={require('../img/mis-documentos/cambiar-dni.webp')} alt="" />
            <p>Conocé más sobre el trámite de exención del pago de peajes para personas con discapacidad</p>
          </div>
        </div>
      </div>

      <Footer
        footerClass={footerClass}
        footerHome={footerHome}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono} />
    </div>
  )
}

export default MisTramites