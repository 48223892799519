import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DniComponent from './components/DniComponent';

const Index = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/dni-component" element={<DniComponent />} />
    </Routes>
  </BrowserRouter>
)

const root = createRoot(document.getElementById('root'));
root.render(<Index />);
