import React from 'react'
import Footer from '../components/Footer'
import '../css/MiPerfil.css'

const MiPerfil = ({ footerClass, footerNovedades, footerTelefono, footerHome, user, goToPage }) => {
  console.log(user)

  return (
    <div id='app' className='app-mi-perfil'>
      <div id="miPerfilHeader">
        <img id='atras' src={require('../img/mi-perfil/atras.webp')} alt="" onClick={() => goToPage()} />
        <p>Mi Perfil</p>
      </div>

      <div id="mainInfo">
        <img src={require('../img/mi-perfil/big-logo.webp')} alt="" />

        <div id="fullname">
          <p>{user.data.name} {user.data.lastname} <img src={require('../img/mi-perfil/verified.webp')} alt="" /></p>
        </div>
        <p id='cuil'>CUIL: {user.data.sex === 'M' ? 20 : 27}{user.data.dni}{user.otherData.cuilLastNumber}</p>
        <p id='identity'>Identidad validada</p>
      </div>

      <div id="datos-personales-container">
        <p>Datos de la cuenta</p>
        <div id="datos-personales">
          <div>
            <p className='main'>Correo electrónico</p>
            <p>{user.user}</p>
          </div>
          <div id="divider-line"></div>
          <div>
            <p className='main'>Contraseña</p>
            <p>{'*'.repeat(user.password.length)}</p>
          </div>
        </div>
      </div>

      <div id="datos-dni-container">
        <p>Tus datos</p>
        <div id="datos-dni">
          <div>
            <p className='main'>Nombre</p>
            <p>{user.data.name}</p>
          </div>
          <div>
            <p className='main'>Apellido</p>
            <p>{user.data.lastname}</p>
          </div>
          <div>
            <p className='main'>DNI</p>
            <p>{user.data.dni}</p>
          </div>
          <div>
            <p className='main'>Sexo</p>
            <p>{user.data.sex === 'M' ? 'Masculino' : 'Femenino'}</p>
          </div>
          <div>
            <p className='main'>Fecha de nacimiento</p>
            <p>{user.data.dateOfBirth.day}/{user.data.dateOfBirth.month}/{user.data.dateOfBirth.year > 24 ? '19' : '20'}{user.data.dateOfBirth.year}</p>
          </div>
          <div>
            <p className='main'>Nacionalidad</p>
            <p>{user.extraData.nationality}</p>
          </div>
        </div>
      </div>

      <Footer
        footerClass={footerClass}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono}
        footerHome={footerHome} />
    </div>
  )
}

export default MiPerfil