import React from 'react';
import '../css/PreviousLogIn.css';
import { app } from '../firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const PreviousLogIn = ({ goToPage, onUserLogin, goToPage2 }) => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');

  const handleLogin = async () => {
    const db = getFirestore(app)
    try {
      if (username && password) {
        const userQuery = query(collection(db, 'users'), where('user', '==', username), where('password', '==', password));
        const querySnapshot = await getDocs(userQuery);
        const userData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
        onUserLogin(userData)
      }

    } catch (err) {
      console.error('Error al autenticar:', err);
    }
  }

  handleLogin()

  return (
    <div id="app" className='app-previous-login' style={{ backgroundColor: 'white' }}>
      <div id="giant-logo">
        <img src={require('../img/previous-log-in/giant-logo.webp')} alt="" />
        <div id="loading" style={username && password ? { display: 'block' } : { display: 'none' }}>
          <p>Iniciando sesión...</p>
        </div>
      </div>

      <div id="previous-login-footer">
        <button id='button1' onClick={() => goToPage()}>
          <p>Ingresar con mi cuenta</p>
        </button>
        <div id="divider-line"></div>
        <p id='account-question'>¿No tenés cuenta en Mi Argentina?</p>
        <button id='button2' onClick={goToPage2}>
          <p>Crear una cuenta</p>
        </button>
      </div>
    </div>
  )
}

export default PreviousLogIn;
